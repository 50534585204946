<template>
  <div v-if="pendiente">
    <b-modal id="modal-enfermeria" size="lg"
      title="Enfermeria"
      :header-bg-variant="pendiente.emergencia ? 'danger' : 'gray'"
      :visible="visible"
      @change="$emit('change', $event)"
    >
      <template #modal-header="{ close }">
        <div class="row">
          <div class="col-sm-6"
          style="font-size: 12px;"
          :class="pendiente.emergencia ? 'color-white': ''">
            <div class="row col-sm-12">
              <div class="col-sm-2">
                <strong>Origen: </strong>
              </div>
              <div class="col-sm-10 pl-0">
                100/23 (10/01/2023 - Hora: 10:33)
              </div>
            </div>
            <div class="col-sm-12">
              <strong>Paciente: </strong> Maria Lurdes Ledesma - Edad: 24 años
            </div>
          </div>
          <div class="col-sm-4"
          style="font-size: 12px;"
          :class="pendiente.emergencia ? 'color-white': ''">
            <div class="col-sm-12">
              <strong>Nº HC: 451</strong>
            </div>
            <div class="col-sm-12">
              <strong>Medico {{ pendiente.medico }}</strong>
            </div>
          </div>
          <div class="col-sm-2 text-right">
            <span class="font-weight-bold align-middle" @click="close()"
              style="cursor: pointer">X</span>
          </div>
        </div>
      </template>
      <div class="row px-3">
        <div class="col-sm-4 pb-3">
          <div class="form-group">
            <label for="estatura"><strong>Estatura:</strong></label>
            <vue-numeric class="form-control"
            v-model="estatura"
            id="estatura"
            separator=","
            :precision="2"
            placeholder=""/>
          </div>
          <div class="form-group">
            <label for="imc">
              <strong>IMC</strong> <span class="font-10">(Indice Mesa Corporal)</span>
            </label>
            <input type="text" id="imc" class="form-control" disabled :value="imc + ' Kg/m2'">
          </div>
          <div class="form-group">
            <label for="fc">
              <strong>FC</strong> <span class="font-10">(Frecuencia Cardiaca)</span>
            </label>
            <input type="text" id="fc" class="form-control">
          </div>
        </div>
        <div class="col-sm-4 pb-3">
          <div class="form-group">
            <label for="peso"><strong>Peso:</strong></label>
            <vue-numeric class="form-control"
            v-model="peso"
            id="peso"
            separator=","
            :precision="2"
            placeholder=""/>
          </div>
          <div class="form-group">
            <label for="fr">
              <strong>FR</strong> <span class="font-10">(Frecuencia Respiratoria)</span>
            </label>
            <input type="text" id="fr" class="form-control">
          </div>
          <div class="form-group">
            <label for="sat">
              <strong>SAT 02</strong> <span class="font-10">(Saturación de Oxigeno)</span>
            </label>
            <input type="text" id="sat" class="form-control">
          </div>
        </div>
        <div class="col-sm-4 pb-3">
          <div class="form-group">
            <label for=""><strong>Temperatura:</strong></label>
            <div class="row">
              <div class="col-sm-4 d-flex align-items-center justify-content-end px-0">
                <label for="oral"><strong>Oral:</strong></label>
              </div>
              <div class="col-sm-8">
                <input type="text" id="oral" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">
              <strong>PA</strong> <span class="font-10">(Presión Arterial)</span>
            </label>
            <div class="row pb-2">
              <div class="col-sm-4 d-flex align-items-center justify-content-end px-0">
                <label for="sistolica"><strong>Sistolica:</strong></label>
              </div>
              <div class="col-sm-8">
                <input type="text" id="sistolica" class="form-control">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 d-flex align-items-center justify-content-end px-0">
                <label for="diastolica"><strong>Diastolica:</strong></label>
              </div>
              <div class="col-sm-8">
                <input type="text" id="diastolica" class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- TABLERO DE INFORMACION -->
      <div v-if="pendiente.emergencia">
        <div class="row px-3">
          <form v-on:submit.prevent="addInformacion" class="col-sm-12">
            <div class="row form-group">
            <div class="col-sm-10">
              <div class="form-group">
                <label for="informacion"><strong>Tablero de Informacion:</strong></label>
                <input type="text" id="informacion" class="form-control"
                v-model="informacion"
                autocomplete="off">
              </div>
            </div>
            <div class="col-sm-2" style="margin: auto; padding-top: 10px;">
              <button class="btn btn-success" type="button" @click="addInformacion">Agregar</button>
            </div>
          </div>
          </form>
        </div>
        <div class="row" style="margin: auto;">
          <div class="col-sm-12" v-for="(informacion, index) in tableroInformacion" :key="index">
            <div class="row">
              <div class="col-sm-10">
                <span>
                  <strong> {{ informacion.user }}</strong>
                  {{ informacion.fecha }} -- Hora {{ informacion.hora }}
                </span>
                <br>
                <span> {{ informacion.descripcion }}</span>
              </div>
              <div class="col-sm-2">
                <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                style="cursor: pointer;" title="Eliminar" @click="deleteInformacion(informacion)">
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FIN TABLERO DE INFORMACION -->
      <div class="row p-4" v-if="!pendiente.emergencia">
        <div class="col-sm-6 bg-gray p-3">
          <div><h4><strong>{{ pendiente.medico }}</strong></h4></div>
          <div class="font-10"><strong>Pediatra</strong></div>
          <div class="pt-3">Turno 1 de: 08:00 a 15:00</div>
        </div>
        <div class="col-sm-6 bg-success p-3 text-center">
          <div>
            <h4 class="text-white"><strong>Consultorio:</strong> Piso 2 - N° 23</h4>
          </div>
          <div>
            <h4><strong class="text-white">Libre</strong></h4>
          </div>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <button class="btn btn-secondary" @click="cancel()">
          Cancelar
        </button>
        <button class="btn bg-custom-green" @click="ok()">
          Guardar
        </button>
        <button class="btn bg-warning" @click="ok()" v-if="!pendiente.emergencia">
          Ausente
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import VueNumeric from 'vue-numeric';
import { round } from 'mathjs';
import { mapState } from 'vuex';

export default {
  name: 'theModalEnfermeria',
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    pendiente: {
      default: null,
      required: true,
    },
  },
  data() {
    return {
      informacion: null,
      tableroInformacion: [],
      peso: 0,
      estatura: 0,
      fecha: moment(new Date()).format('DD/MM/YYYY'),
    };
  },
  methods: {
    addInformacion() {
      const INFORMACION = {
        id: new Date().getTime(),
        descripcion: this.informacion,
        user: this.user.name,
        user_id: this.user.id,
        fecha: this.fecha,
        hora: moment().format('HH:mm:ss'),
      };
      this.tableroInformacion.push(INFORMACION);
      this.informacion = null;
    },
    deleteInformacion(item) {
      const NRO = item.id;
      const INDEX = this.tableroInformacion.findIndex((el) => el.id === NRO);
      this.tableroInformacion.splice(INDEX, 1);
    },
  },
  computed: {
    imc() {
      let total = 0;
      if (this.peso !== 0 && this.estatura !== 0) {
        total = parseFloat(this.peso) / parseFloat(this.estatura * this.estatura);
      }
      total = round(total, 2);
      return total;
    },
    ...mapState('main', [
      'user',
    ]),
  },
  components: {
    VueNumeric,
  },
};
</script>

<style scoped>
.modal-header {
  display:block !important;
}
.font-10 {
  font-size: 10px;
}
.bg-gray {
  background-color: rgb(200,200,200);
}
.color-white {
  color: white;
}
</style>

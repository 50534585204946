<template>
  <div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-4">
                <div class="breadcrumb mb-2 custom-top-padding">
                  <h1 class="mb-0"><strong>{{getTitleUI}}</strong></h1>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-5">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input type="checkbox" id="filter"
                      :checked="estado"
                      v-model="estado">
                    <span class="slider"></span>
                    <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row py-3">
              <div class="col-sm-8">
                <strong>
                  <h4>Pendientes: {{pendientes.length}}</h4>
                </strong>
                <!-- <div>Ver Cerrados</div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 p-3" v-for="(pendiente, index) in pendientes" :key="index">
                  <div class="card" @click="onClickHeaderCard(pendiente)" style="cursor: pointer;">
                    <div
                      class="card-header pb-0 border-radius-top"
                      style="cursor:pointer;"
                      :class="pendiente.headerClass"
                    >
                      <div class="row">
                        <div class="col-sm-5">
                          <h4><strong>N° {{pendiente.numero}}</strong></h4>
                        </div>
                        <div class="col-sm-7 text-right">
                          <h4><strong>Hora: {{pendiente.hora}}</strong></h4>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div>
                        <h5 class="text-success"><strong>{{pendiente.paciente}}</strong></h5>
                      </div>
                      <div>
                        <h5 class="mb-0"><b>{{pendiente.medico}}</b></h5>
                      </div>
                      <div>{{pendiente.categoria}}</div>
                      <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-8 text-right" style="font-size: 9px;">
                          <strong>Origen: </strong>{{pendiente.origen}}
                          <br>
                          <strong>Fecha: </strong>{{pendiente.fecha}}</div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modalEnfermeria
      v-model="showEnfermeria"
      :pendiente="pendiente"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FUNCIONALIDADES } from '@/utils/globalConventions';
import modalEnfermeria from '../components/theModalEnfermeria.vue';

export default {
  name: 'enfermeriaIndex',
  components: {
    modalEnfermeria,
  },
  data() {
    return {
      estado: true,
      pendientes: [
        {
          numero: 1,
          paciente: 'Maria Lurdes Caballero',
          medico: 'Dra. Lurdes Magento',
          categoria: 'Pediatra',
          origen: '1000/23 - 01/01/2023',
          fecha: '10/01/2023',
          hora: '11:30',
          headerClass: 'bg-gray',
          emergencia: false,
        },
        {
          numero: 2,
          paciente: 'Veronica Pascual Valeriano',
          medico: 'Dra. Rosio Rodriguez',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
          headerClass: 'bg-danger',
          emergencia: true,
        },
        {
          numero: 3,
          paciente: 'Veronica Pascual Valeriano',
          medico: 'Dr. Ezequiel Arias',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
          headerClass: 'bg-warning',
          emergencia: false,
        },
        {
          numero: 4,
          paciente: 'Veronica Pascual Valeriano',
          medico: 'Dra. Lurdes Magento',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
          headerClass: 'bg-gray',
          emergencia: false,
        },
        {
          numero: 5,
          paciente: 'Veronica Pascual Valeriano',
          medico: 'Dra. Lurdes Magento',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
          headerClass: 'bg-gray',
          emergencia: false,
        },
        {
          numero: 6,
          paciente: 'Veronica Pascual Valeriano',
          medico: 'Dra. Lurdes Magento',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
          headerClass: 'bg-gray',
          emergencia: false,
        },
        {
          numero: 7,
          paciente: 'Veronica Pascual Valeriano',
          medico: 'Dra. Lurdes Magento',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
          headerClass: 'bg-gray',
          emergencia: false,
        },
        {
          numero: 8,
          paciente: 'Veronica Pascual Valeriano',
          medico: 'Dra. Lurdes Magento',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
          headerClass: 'bg-gray',
          emergencia: false,
        },
      ],
      showEnfermeria: false,
      pendiente: null,
    };
  },
  computed: {
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
    getTitleUI() {
      const ITEM = this.getFuncionalidades.find((el) => el.id === FUNCIONALIDADES.ENFERMERIA);
      if (ITEM !== null) {
        return ITEM.descripcion;
      }
      return '';
    },
    ...mapGetters('main', [
      'getFuncionalidades',
    ]),
  },
  methods: {
    onClickHeaderCard(item) {
      this.showEnfermeria = true;
      this.pendiente = item;
    },
    onClickEliminarIcon() {
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: rgb(182, 182, 182);
}
.border-radius-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.custom-top-padding {
  padding-top: 5.53px;
}
</style>
